import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-7.svg';
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-3.svg';

const Container = tw.section`relative`;
const InnerContainer = tw.div`max-w-screen-xl mx-auto py-10 lg:py-16 px-6 lg:px-10`;
const Heading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
const HistoryOuterConatiner = tw.div`w-full relative pt-10 lg:pt-16 flex justify-center`;
const HistoryContainer = tw.ol`list-none`;

const HistoryItem = styled.li`
  ${tw`relative flex list-none`}
  ${(props) => css``}
`;

const YearText = tw.h3`text-2xl lg:text-4xl font-medium w-[20px] lg:w-[100px]`;
const ContentContainer = styled.dl`
  ${tw`relative h-full pb-20 ml-20 pt-[7px] `}
  &::after {
    position: absolute;
    content: '';
    background-color: white;
    border: solid 1px #006aee;
    width: 14px;
    height: 14px;
    left: -6px;
    top: 7px;
    border-radius: 14px;
    box-sizing: border-box;
  }
  &::before {
    position: absolute;
    content: '';
    background-color: #4f7cff;
    width: 2px;
    height: 100%;
    opacity: 0.3;
  }
  ${(props) => [
    props.first &&
      css`
        &::after {
          position: absolute;
          content: '';
          background-color: #4f7cff;
          width: 14px;
          height: 14px;
          left: -6px;
          top: 7px;
          border-radius: 14px;
          box-sizing: border-box;
        }
      `,
    props.last &&
      css`
        &::before {
          position: absolute;
          content: '';
          opacity: 0;
        }
      `,
  ]}
`;
const ContentText = styled.dd`
  ${tw`text-base lg:text-lg font-normal pl-10 lg:pl-20`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  return (
    <Container id="History">
      <InnerContainer>
        <Heading>History</Heading>
        <HistoryOuterConatiner>
          <HistoryContainer>
            {history.map((item, index) => (
              <HistoryItem key={index}>
                <YearText>{item.year}</YearText>
                <ContentContainer
                  first={index == 0}
                  last={index == history.length - 1}
                >
                  {item.contents.map((text, index) => (
                    <ContentText key={index}>{text}</ContentText>
                  ))}
                </ContentContainer>
              </HistoryItem>
            ))}
          </HistoryContainer>
        </HistoryOuterConatiner>
      </InnerContainer>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const history = [
  {
    year: '2023',
    contents: ['makepic 커머스 AI 서비스 출시'],
  },
  {
    year: '2022',
    contents: [
      'PETWIZ AI 스마트 자동급식기 출시(KT)',
      'MANBOO 웹툰 콘티 제작툴 출시',
      '(주)로켓웍스 사명 변경',
    ],
  },
  {
    year: '2020',
    contents: ['네컷만화 말로 그리는 AI 콘텐츠 에디터 출시'],
  },
  {
    year: '2018',
    contents: ['SD-B190S mini phone 출시(SKT)', 'SMR-100K IRON Egg출시(KT)'],
  },
  {
    year: '2017',
    contents: ['SD-W530S Kidsphone Service JooN Special Edition 출시(SKT)'],
  },
  {
    year: '2015',
    contents: ['IF-W520S KidsPhone Service Joon2 출시(SKT)'],
  },
  {
    year: '2014',
    contents: ['IF-W509S  Kidsphone Service JooN 출시 (SKT)'],
  },
  {
    year: '2011',
    contents: ['러빙아이, 올레모바일지킴이 서비스 런칭 (KT)'],
  },
  {
    year: '2010',
    contents: ['(주)에스모바일 설립'],
  },
];
