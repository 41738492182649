import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled from 'styled-components';
import { SectionHeading } from 'components/misc/Headings';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons';
import { ReactComponent as PriceIcon } from 'feather-icons/dist/icons/dollar-sign.svg';
import { ReactComponent as LocationIcon } from 'feather-icons/dist/icons/map-pin.svg';
import { ReactComponent as StarIcon } from 'feather-icons/dist/icons/star.svg';
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';

import MakepicNews from 'images/news/makpic-news.jpg';
import PetwizNews from 'images/news/petwiz-news.jpg';
import CookizMiniNews from 'images/news/cookiz-mini-news.jpg';
import JoonNuguNews from 'images/news/joon-nugu-news.jpg';
import JoonSeNews from 'images/news/joonse-news.jpg';
import CookizAbroadNews from 'images/news/cookiz-abroad-news.jpg';

const Container = tw.div`relative -mt-[80px] pt-[80px]`;
const Content = tw.div`max-w-screen-xl mx-auto py-6 lg:py-20`;

const HeadingWithControl = tw.div`flex items-center sm:items-stretch flex-row justify-between px-10`;
const Heading = tw(SectionHeading)`text-center w-full pb-6`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-4 lg:mt-6 overflow-x-hidden`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.a`h-full flex! flex-col sm:border max-w-sm relative focus:outline-none shadow-lg shadow-gray-400 select-none [-webkit-user-drag: none] mx-4 lg:mx-0 !w-[90%] lg:!w-full box-content`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center`,
]);

const TextInfo = tw.div`p-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl font-bold transition duration-300 hover:text-primary-500`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;
const Date = tw.h6`text-lg font-medium py-2`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 line-clamp-3`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  let firstClientX, clientX;
  const preventTouch = (e) => {
    const minValue = 10; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };
  let containerRef = React.createRef();

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
    touchThreshold: 50,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart);
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        });
      }
    };
  }, []);

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: MakepicNews,
      title:
        "로켓웍스, 인공지능 기반 패션 사진 배경 생성 서비스 'makepic' 출시",
      date: '23/07/03',
      description:
        '로켓웍스가 인공지능(AI) 기반의 커머스용 패션 사진 배경 교체 서비스 메이크픽을 출시한다고 3일 발표했다.메이크픽은 스튜디오 및 야외 촬영 비용과 시간을 줄여주는 서비스로, 지난 6개월간의 자체 개발을 통해 생성형 AI 기술을 이용한 relighting과 가속화 기술을 적용한 첫 서비스다. 이 서비스를 통해 사용자들은 커머스용 패션 사진에 대해 인공지능을 통해 손쉽게 배경을 바꿀 수 있으며, 이를 통해 기업들은 촬영 및 후처리 과정에서 발생하는 비용과 시간을 크게 절약할 수 있다. 메이크픽은 무료 체험 서비스와 사전 예약 혜택을 제공한다.',
      url: 'http://www.it-b.co.kr/news/articleView.html?idxno=69013',
    },
    {
      imageSrc: PetwizNews,
      title: 'KT, 자동급식기 제공하는 반려견 디바이스팩 출시',
      date: '22/05/30',
      description:
        'KT는 반려견 활동량을 분석하는 사물인터넷(IoT) 기반 웨어러블 기기와 자동급식기를 제공하는 반려견 디바이스팩을 출시했다고 30일 밝혔다. 반려견 디바이스팩은 5G 초이스 요금제 중 디바이스 초이스에 가입하면 선택 가능하다. KT는 반려견 디바이스 2종에 대한 할부원금을 24개월간 지원해 사용자는 무료로 디바이스를 이용할 수 있다.',
      url: 'https://zdnet.co.kr/view/?no=20220530094640',
    },
    {
      imageSrc: CookizMiniNews,
      title: 'SKT, 스마트폰 중독·유해물 노출 차단한 키즈폰 ‘쿠키즈 미니’ 출시',
      date: '19/03/06',
      description:
        'SK텔레콤은 스마트폰 중독과 유해물 노출 위험을 차단한 어린이 전용폰 ‘쿠키즈 미니폰’을 6일 출시했다.인터넷 웹서핑과 앱 스토어를 차단한 대신 전용 메신저인 ‘미니톡’을 제공해 친구들과의 소통을 돕는 제품이다.외국어 공부에 유용한 네이버사전과 번역 서비스 ‘파파고’를 탑재했고 시청각 학습에 필요한 MP3및 동영상 재생 기능을 지원한다. ',
      url: 'https://sports.khan.co.kr/bizlife/sk_index.html?art_id=201803061040003&sec_id=561101&pt=nv',
    },
    {
      imageSrc: JoonNuguNews,
      title: "SKT, 쿠키즈워치 '준3'와 'SE'에 AI 탑재한 ‘준x누구’ 출시",
      date: '17/10/29',
      description:
        '[뉴스웍스=박경보 기자] SK텔레콤은 ‘쿠키즈워치 준(JooN)3’와 준 스페셜 에디션에 자사의 인공지능)AI) 플랫폼 ‘누구(NUGU)’를 탑재한 차세대 키즈 웨어러블 서비스 ‘준x누구(JooN x NUGU)’를 30일부터 선보인다.‘준x누구’를 이용하면 어린이가 음성으로 전화를 걸 수 있게 된다. 또한 날씨, 일정, 한영사전, 위키피디아 검색 등의 기능도 음성을 통해 이용할 수 있다.',
      url: 'https://www.newsworks.co.kr/news/articleView.html?idxno=145506',
    },
    {
      imageSrc: JoonSeNews,
      title: "SK텔레콤, 쿠키즈워치 '준 스페셜 에디션' 3종 출시",
      date: '17.04.30',
      description:
        "[아이뉴스24 양태훈기자] SK텔레콤(대표 박정호)이 디즈니 등과 제휴해 디즈니와 마블의 인기 캐릭터가 디자인된 쿠키즈워치 '준(JooN) 스페셜 에디션' 3종을 2일 출시한다고 발표했다. SK텔레콤은 미취학 아동부터 초등학생 등 어린이를 위해 음성통화는 물론, 위치조회가 가능한 쿠키즈워치 준 시리즈를 매년 출시하고 있다.",
      url: 'https://www.inews24.com/view/1020701',
    },
    {
      imageSrc: CookizAbroadNews,
      title: '국산 키즈폰, 해외간다…SKT·인포마크·에스모바일 제휴',
      date: '16.04.29',
      description:
        '[아시아경제 강희종 기자] SK텔레콤 은 중소기업 인포마크 및 에스모바일과 함께 어린이 전용 단말기 T키즈폰의 해외 진출을 공동으로 추진하기로 하고 28일 양해각서(MoU)를 체결했다. 이번 협약을 통해 SK텔레콤은 해외 통신사업자와의 네트워크를 활용해 T키즈폰의 글로벌 진출에 앞장서고, 단말기 개발 및 플랫폼 서버 구축 등의 투자도 진행할 계획이다. 인포마크는 웨어러블 기기 전문업체로 T키즈폰 외에 핀테크용 웨어러블 단말기도 출시한 바 있다. 에스모바일은 T키즈폰의 플랫폼 및 앱을 개발한 소프트웨어 솔루션 전문기업이다. 양사는 SK텔레콤과 함께 국가별 디바이스 개발 및 공동 마케팅에 적극 나서기로 했다.',
      url: 'http://view.asiae.co.kr/news/view.htm?idxno=2016042909182212204',
    },
  ];

  return (
    <Container id="News">
      <Content>
        <HeadingWithControl>
          <Heading>News</Heading>
          {/* <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls> */}
        </HeadingWithControl>
        <div tw="w-full" ref={containerRef}>
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {cards.map((card, index) => (
              <Card key={index} href={card.url} target="_blank">
                <CardImage imageSrc={card.imageSrc} />
                <TextInfo>
                  <TitleReviewContainer>
                    <Title>{card.title}</Title>
                  </TitleReviewContainer>
                  <Date>{card.date}</Date>
                  <Description>더보기...</Description>
                </TextInfo>
              </Card>
            ))}
          </CardSlider>
        </div>
      </Content>
    </Container>
  );
};
