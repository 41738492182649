import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import { SectionHeading } from 'components/misc/Headings.js';

import defaultCardImage from '../../images/shield-icon.svg';

import { ReactComponent as SvgDecoratorBlob3 } from '../../images/svg-decorator-blob-3.svg';

import SupportIconImage from '../../images/support-icon.svg';
import ShieldIconImage from '../../images/shield-icon.svg';
import CustomizeIconImage from '../../images/customize-icon.svg';
import FastIconImage from '../../images/fast-icon.svg';
import ReliableIconImage from '../../images/reliable-icon.svg';
import SimpleIconImage from '../../images/simple-icon.svg';

import FeatureAi1 from '../../images/feature-ai1.jpg';
import FeatureAi2 from '../../images/feature-ai2.jpg';
import FeatureAi3 from '../../images/feature-ai3.jpg';

const Container = tw.section`relative pt-[50px] lg:pt-[80px]`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto pt-10 pb-10 md:pt-16 md:pb-0 px-10 lg:px-0`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-0 lg:px-6 flex w-full`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-100 rounded-lg mt-12 w-full`}
  .imageContainer {
    ${tw`border-2 border-primary-100 text-center rounded-full p-6 shrink-0 relative`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-2xl lg:text-3xl leading-none text-primary-300 block`}
  }

  .description {
    ${tw`mt-3 font-medium text-secondary-100 leading-loose text-[17px] lg:text-lg text-left inline-block`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const cards = [
    {
      imageSrc: FeatureAi1,
      title: 'Generative AI',
      description: (
        <>
          &middot;&nbsp;&nbsp;GAN
          <br />
          &middot;&nbsp;&nbsp;Image to Image
          <br />
          &middot;&nbsp;&nbsp;Hyper-Personalization
        </>
      ),
    },
    {
      imageSrc: FeatureAi2,
      title: 'Image Processing',
      description: (
        <>
          &middot;&nbsp;&nbsp;Color Harmonization
          <br />
          &middot;&nbsp;&nbsp;Relighting
          <br />
          &middot;&nbsp;&nbsp;WebGL
        </>
      ),
    },
    {
      imageSrc: FeatureAi3,
      title: 'AI SaaS',
      description: (
        <>
          &middot;&nbsp;&nbsp;AI Pipeline
          <br />
          &middot;&nbsp;&nbsp;Triton
          <br />
          &middot;&nbsp;&nbsp;Tensor RT
        </>
      ),
    },
  ];

  return (
    <Container id="Feature">
      <ThreeColumnContainer>
        <Heading>AI Technology</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description">{card.description || ''}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
