import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import logo from '../../images/logo.svg';
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg';
import logoLight from '../../images/logo-light.png';
import NBlogIcon from '../../images/nblog-icon.png';

import { ReactComponent as EmailNewsletterIconBase } from '../../images/email-newsletter-icon.svg';
import { OutlineButton as OutlineButtonBase } from 'components/misc/Buttons.js';

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100`;
const Content = tw.div`max-w-screen-xl w-full mx-auto py-8 lg:py-12`;
const RowContainer = tw.div`flex flex-col-reverse lg:flex-row justify-center lg:justify-around `;
const Row = tw.div`flex items-start justify-center flex-col px-8 relative`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;
const LinksContainer = tw.div`mt-0 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row py-5 w-full lg:w-auto`;

const ContactTitle = tw.div`flex justify-center`;
const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-5 h-5 mr-2`;

const ContactContainer = styled.div`
  ${tw`text-sm font-medium flex flex-col w-full`}
  p {
    ${tw`text-gray-600`}
  }
`;

const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 
hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-2`;
const LinkBold = tw(Link)`font-bold text-gray-500 text-sm`;

const ContactButton = styled(OutlineButtonBase).attrs({ as: 'a' })`
  ${tw`w-full text-center flex items-center justify-center mt-2 lg:mt-4 text-sm font-semibold py-6 text-white outline-white hocus:bg-white hocus:text-gray-700 cursor-pointer max-w-lg mx-auto`}
  span {
    ${tw`font-bold text-lg`}
  }
`;

const SocialLinksContainer = tw.div`mt-2`;
const SocialLink = styled.a`
  ${tw`cursor-pointer block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  img, svg {
    ${tw`w-8 h-8`}
  }
`;

const AddressText = tw.p`text-center mt-2 font-medium tracking-wide text-[12px] md:text-sm text-gray-600 mx-auto lg:mx-0`;
const AddressContainer = styled.div`
  ${tw`relative mx-auto lg:mx-0`}
  p {
    ${tw`text-center mt-2 font-medium tracking-wide text-[12px] md:text-sm text-gray-600`}
  }
  img {
    ${tw`w-8 h-8 absolute -right-[10px] -top-[25px]`}
  }
`;

const CopyrightText = tw.p`text-center mt-2 font-medium tracking-wide text-sm text-gray-600 mx-auto lg:mx-0`;
export default () => {
  return (
    <Container>
      <Content>
        <RowContainer>
          <Row>
            {/* <LogoContainer>
            <LogoImg src={logoLight} />
          </LogoContainer> */}
            <LinksContainer>
              <LinkBold
                href="https://silent-appliance-eb1.notion.site/c7c423c4003c451a8149344a97f54a43?pvs=4"
                target="_blank"
              >
                {'개인(위치)정보처리방침'}
              </LinkBold>
              <LinkBold
                href="https://silent-appliance-eb1.notion.site/bce1de39c17e477eaca6cbdee7ccc0c4?pvs=4"
                target="_blank"
              >
                {'위치기반서비스 이용약관'}
              </LinkBold>
              {/* <Link
              href="#"
              onClick={() => {
                window.location.href = 'mailto:makepic@rocketworks.io';
              }}
            >
              사업 제휴 및 문의
            </Link> */}
            </LinksContainer>
            {/* <ContactContainer>
              <div>
                제휴 및 문의:
                <Link
                  href="#"
                  onClick={() => {
                    window.location.href = 'mailto:rocketworks@rocketworks.io';
                  }}
                >
                  <span tw="text-blue-500">rocketworks@rocketworks.io</span>
                </Link>
              </div>
            </ContactContainer> */}
            {/*<SocialLinksContainer>
              <SocialLink
                href="https://blog.naver.com/smobilepeople"
                target="_blank"
                draggable="false"
                rel="noopener"
              >
                <img src={NBlogIcon} />
              </SocialLink>
               <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> 
            </SocialLinksContainer>*/}
            <AddressContainer>
              <p>13591 경기도 성남시 분당구 황새울로 354,8층 (주)로켓웍스</p>
              <SocialLink
                href="https://blog.naver.com/rocketworks"
                target="_blank"
                draggable="false"
                rel="noopener"
              >
                <img src={NBlogIcon} alt="네이버 블로그" />
              </SocialLink>
            </AddressContainer>
            <AddressText>
              사업자등록번호: 107-87-29935 | 통신판매업신고번호:
              2012-경기성남-0765 | 대표자명: 김정일
            </AddressText>
            <CopyrightText>
              &copy; Copyright 2023, Rocketworks Inc. All Rights Reserved.
            </CopyrightText>
          </Row>
          <Row>
            <ContactContainer>
              <ContactTitle>
                <p>제휴 및 문의 : rocketworks@rocketworks.io</p>
              </ContactTitle>
              <ContactButton
                onClick={(e) => {
                  window.location.href = 'mailto:rocketworks@rocketworks.io';
                  e.currentTarget.blur();
                }}
              >
                <EmailNewsletterIcon />
                <span>사업 제휴 및 문의</span>
              </ContactButton>
            </ContactContainer>
          </Row>
        </RowContainer>
      </Content>
    </Container>
  );
};
