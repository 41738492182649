import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../headers/light.js';
import { Slide, Fade } from 'react-awesome-reveal';

import MainBgMp4 from 'videos/0713_rocketworks.mp4';
import useShowButtonWhenScrollDown from 'helpers/useShowHeader.js';
import logo from '../../images/logo.png';
import logoLight from '../../images/logo-light.png';

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full fixed z-20 px-8 py-0 lg:py-6 border-b-0`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
    ${({ transparent }) =>
      transparent &&
      tw`text-gray-800 hocus:text-gray-900 hover:border-b-gray-900`}
  }

  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
    ${({ transparent }) => transparent && tw`text-gray-800`}
  }
  ${({ transparent }) =>
    transparent && tw`bg-white text-black border-b-[1px] border-b-gray-300`}
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container = styled.section`
  ${tw`relative bg-center bg-cover h-screen min-h-144`}
`;

const MainBgVideo = tw.div`absolute inset-0 w-full h-full`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;
const TextOverlayContainer = styled.div`
  ${tw`text-white my-auto ml-6 lg:ml-32`}
`;
const MainSubDescription = tw.span`font-normal text-gray-500 text-xl lg:text-2xl`;

//const TextOverlayContainer = tw.div`text-white my-auto ml-6 lg:ml-32`;
const HeroContainer = tw.div`z-20 relative mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const { show } = useShowButtonWhenScrollDown();

  const logosrc = show ? logo : logoLight;

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#Home">Home</NavLink>
      <NavLink href="#Service">Services</NavLink>
      <NavLink href="#Product">Products</NavLink>
      <NavLink href="#News">News</NavLink>
      <NavLink
        href="https://silent-appliance-eb1.notion.site/a9b9db1a84c34d3e9bebf5ba5d76973c?v=4154bd4d8bde4a35a20e242726e8f70c&pvs=4"
        target="_blank"
      >
        Support
      </NavLink>
    </NavLinks>,
  ];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logosrc} alt="logo" />
    </LogoLink>
  );

  return (
    <Container id="Home">
      <OpacityOverlay />
      <MainBgVideo>
        <video
          preload="auto"
          autoPlay
          loop
          muted
          playsInline={true}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src={MainBgMp4} type="video/mp4" />
        </video>
      </MainBgVideo>
      <HeroContainer>
        <StyledHeader
          links={navLinks}
          logoLink={defaultLogoLink}
          transparent={show}
        />

        <TextOverlayContainer>
          <Slide duration={1000}>
            <span tw="font-bold text-3xl leading-tight lg:text-6xl">
              Better Future
              <br />
              with AI Technology
            </span>
          </Slide>
          <br />
          <br />
          <Fade delay={1000} damping={1} duration={1500}>
            <MainSubDescription>
              AI 기술로 더 나은 미래를 그리다
            </MainSubDescription>
          </Fade>
          <Fade delay={2000} damping={1} duration={1500}>
            <MainSubDescription>
              사람과 세상을 돕는 인공지능 전문 기업
            </MainSubDescription>
          </Fade>
        </TextOverlayContainer>
      </HeroContainer>
    </Container>
  );
};
