import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js';
import { ContentWithPaddingXl } from 'components/misc/Layouts.js';

import MakepicNews from 'images/news/makpic-news.jpg';
import PetwizNews from 'images/news/petwiz-news.jpg';
import MiniPoneNews from 'images/news/cookiz-mini-news.jpg';
import JoonNuguNews from 'images/news/joon-nugu-news.jpg';

const Container = tw.div`relative lg:pt-[80px]`;
const Content = styled(ContentWithPaddingXl)`
  ${tw`px-10`}
`;
const Row = tw.div`flex flex-col lg:flex-row -mb-10`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;
const PopularPostsContainer = tw.div`lg:w-2/3`;
const PostsContainer = tw.div`mt-4 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(
  motion.a
)`block sm:max-w-sm cursor-pointer mb-4 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)((props) => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
]);
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`;
const Date = tw.h6`mt-2 text-lg font-bold py-2`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm line-clamp-3`;

const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`text-secondary-100 text-sm`;

const RecentPostsContainer = styled.div`
  ${tw`mt-4 lg:mt-16 lg:w-1/3`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-10 max-w-none w-full sm:w-1/2 lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-20 w-20 shrink-0`}
  }
`;
const PostTextContainer = tw.div``;

export default () => {
  // This setting is for animating the post background image on hover
  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: '100%',
    },
    hover: {
      backgroundSize: '110%',
    },
  };

  //Recommended: Only 2 Items
  const popularPosts = [
    {
      postImageSrc: MakepicNews,
      title: '로켓웍스, 인공지능 기반 패션 사진 배경 생성 서비스 makepic 출시',
      date: '23/07/03',
      description:
        '로켓웍스가 인공지능(AI) 기반의 커머스용 패션 사진 배경 교체 서비스 메이크픽을 출시한다고 3일 발표했다.메이크픽은 스튜디오 및 야외 촬영 비용과 시간을 줄여주는 서비스로, 지난 6개월간의 자체 개발을 통해 생성형 AI 기술을 이용한 relighting과 가속화 기술을 적용한 첫 서비스다. 이 서비스를 통해 사용자들은 커머스용 패션 사진에 대해 인공지능을 통해 손쉽게 배경을 바꿀 수 있으며, 이를 통해 기업들은 촬영 및 후처리 과정에서 발생하는 비용과 시간을 크게 절약할 수 있다. 메이크픽은 무료 체험 서비스와 사전 예약 혜택을 제공한다.',
      url: 'http://www.it-b.co.kr/news/articleView.html?idxno=69013',
    },
    {
      postImageSrc: PetwizNews,
      title: 'KT, 자동급식기 제공하는 반려견 디바이스팩 출시',
      date: '22/05/30',
      description:
        'KT는 반려견 활동량을 분석하는 사물인터넷(IoT) 기반 웨어러블 기기와 자동급식기를 제공하는 반려견 디바이스팩을 출시했다고 30일 밝혔다. 반려견 디바이스팩은 5G 초이스 요금제 중 디바이스 초이스에 가입하면 선택 가능하다. KT는 반려견 디바이스 2종에 대한 할부원금을 24개월간 지원해 사용자는 무료로 디바이스를 이용할 수 있다.',
      url: 'https://zdnet.co.kr/view/?no=20220530094640',
    },
  ];

  const recentPosts = [
    {
      postImageSrc: MiniPoneNews,
      title: "SKT, 어린이 전용 스마트폰 '미니폰 마블 블랙' 출시",
      date: '2019.01.29',
      url: 'https://www.hankyung.com/news/article/201901291231o',
    },
    {
      postImageSrc: JoonNuguNews,
      title: 'SK텔레콤, 쿠키즈워치 준3·SE에 ‘누구’ 탑재',
      date: '2017.10.29',
      url: 'http://www.m-i.kr/news/articleView.html?idxno=354079',
    },
  ];

  return (
    <Container id="News">
      <Content>
        <Row>
          <PopularPostsContainer>
            <Heading>뉴스</Heading>
            <PostsContainer>
              {popularPosts.map((post, index) => (
                <Post
                  key={index}
                  href={post.url}
                  target="_blank"
                  className="group"
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <Date>{post.date}</Date>
                  <Image
                    transition={{ duration: 0.3 }}
                    variants={postBackgroundSizeAnimation}
                    $imageSrc={post.postImageSrc}
                  />
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                </Post>
              ))}
            </PostsContainer>
          </PopularPostsContainer>
          <RecentPostsContainer>
            {/* <Heading>Recent Posts</Heading> */}
            <PostsContainer>
              {recentPosts.map((post, index) => (
                <Post key={index} href={post.url} className="group">
                  <PostTextContainer>
                    <Title>{post.title}</Title>
                    <AuthorName>{post.date}</AuthorName>
                  </PostTextContainer>
                  <Image $imageSrc={post.postImageSrc} />
                </Post>
              ))}
            </PostsContainer>
          </RecentPostsContainer>
        </Row>
      </Content>
    </Container>
  );
};
