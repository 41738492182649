import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/BackgroundAsMp4';
import Features from 'components/features/DashedBorderSixFeatures';
import Services1 from 'components/features/Services.js';
import Services from 'components/blogs/ThreeColSimpleWithImageAndDashedBorder.js';
import RecentNews1 from 'components/blogs/PopularAndRecentBlogPosts.js';
import RecentNews from 'components/blogs/ThreeColForPosts.js';
import History from 'components/history/history';
import Footer from 'components/footers/MiniCenteredFooter';

export default ({}) => (
  <AnimationRevealPage disabled>
    <Hero />
    <Features />
    {/* <Services1 /> */}
    <Services />
    <RecentNews />
    <History />
    <Footer />
  </AnimationRevealPage>
);
