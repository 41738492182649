import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import MainFeature from 'components/features/TwoColWithButtonAndImage.js';
import {
  SectionHeading as HeadingTitle,
  Subheading,
} from 'components/misc/Headings.js';

import ManbooGuide from '../../images/manboo-guide.gif';
import ForcutGuide from '../../images/fourcut-about.png';
import PetWizGuide from '../../images/petwiz-guide.jpg';
import MakepicGuide from '../../images/makepic-guide.gif';
import MiniPhoneGuide from '../../images/miniphone-guide.png';

import ManbooGuideMp4 from '../../videos/manboo-guide.mp4';
import FourcutGuideMp4 from '../../videos/fourcut-guide.mp4';
import MiniPhoneGuideMp4 from '../../videos/miniphone-guide.mp4';
import PetWizGuideMp4 from '../../videos/petwiz-guide.mp4';

const Container = tw.section`relative pt-[50px] lg:pt-[160px]`;
const imageCss = tw`rounded-4xl max-h-[500px] w-full`;

export default ({}) => {
  return (
    <Container id="Solution">
      <HeadingTitle>
        The our<span tw="text-primary-500"> Solutions & Products</span>
      </HeadingTitle>
      <MainFeature
        subheading="AI - Web Service"
        heading={
          <>
            커머스 생성 AI
            <br />
            메이크픽(makepic.ai)
          </>
        }
        description=""
        imageSrc={MakepicGuide}
        imagecss={imageCss}
        primaryButtonUrl="https://makepic.ai"
        textOnLeft={false}
      />
      <MainFeature
        subheading="AI - Mobile Application"
        heading={<>PETWIZ - AI 스마트 자동 급식기</>}
        description={
          <>
            당신의 반려동물을 위한 IOT 케어 솔루션. AI 스마트 자동 급식기로
            아이의 위치를 확인하면서, 건강 케어를 한꺼번에.
            <br />
          </>
        }
        videoSrc={PetWizGuideMp4}
        //imageSrc={PetWizGuide}
        //imagecss={imageCss}
        primaryButtonUrl="https://petwiz.kr"
        textOnLeft={true}
      />
      <MainFeature
        subheading="Web Service"
        heading={<>MANBOO - 콘티 제작 솔루션</>}
        description={
          <>
            당신의 창작을 도와주는 콘티 제작 솔루션.
            <br />
            설치 필요없이 웹으로 빠르게 간편하게 제작이 가능합니다.
            <br />
          </>
        }
        videoSrc={ManbooGuideMp4}
        primaryButtonUrl="https://manboo.io"
        textOnLeft={false}
      />
      <MainFeature
        subheading="AI - Mobile Application"
        heading={<>네컷만화 - AI 콘텐츠 제작 플랫폼</>}
        description={
          <>
            말로그리는 AI 콘텐츠 에디터. 짧지만 재미있는 네컷 만화, 만화를
            만들어 보세요.
            <br />
          </>
        }
        videoSrc={FourcutGuideMp4}
        //imageSrc={ForcutGuide}
        //imagecss={imageCss}
        primaryButtonUrl="https://play.google.com/store/apps/details?id=kr.smobile.app.t3"
        textOnLeft={true}
      />
      <MainFeature
        subheading="Mobile Device"
        heading={<>미니폰 - 프리미엄 키즈폰</>}
        description={
          <>
            유해 차단, 안전 지킴이 프리미엄 키즈폰. 아이의 위치 확인 기능과
            메신저 기능을 함께 이용할 수 있습니다.
            <br />
          </>
        }
        videoSrc={MiniPhoneGuideMp4}
        //imageSrc={MiniPhoneGuide}
        //imagecss={imageCss}
        primaryButtonUrl="https://silent-appliance-eb1.notion.site/mini-f6372bb20c024312ad580ccae538e5af?pvs=4"
        textOnLeft={false}
      />
    </Container>
  );
};
